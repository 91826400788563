import React, { useState } from 'react';
import { DefaultLayout } from "../../reusable/Templates";
import { MainNewLogo } from "../../_data/homedata";
import echoLogo from "../../assets/images/common/logo.jpg";
import { facebook, instagram, linkedin, twitter, youtube } from "../../reusable/organisms/Footer/import";
import * as styles from "./404.module.scss";
import * as style from "./404.css";

const NoMatchPage = () => {
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Here you can add the logic to send the email
  //   const response = await fetch('/api/send-email', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email }),
  //   });

  //   if (response.ok) {
  //     setMessage('Thank you! You will be notified soon.');
  //   } else {
  //     setMessage('Something went wrong. Please try again.');
  //   }
  // };
  return (
    <DefaultLayout>
      <div className={styles.comingSoon}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.comingSoonUl}>
                <div className={styles.logo}>
                  <img src={MainNewLogo} alt="Echo logo" className={styles.mainLogoComingSoon} />
                </div>
                <div className={styles.comingSoonHeading}>
                  <h1 className={styles.textUppercase}>Coming soon</h1>
                </div>
                <div className={styles.socialLinks}>
                  <ul className={styles.socialLinksComingSoon}>
                    <li>
                      <a target='blank' href="https://www.facebook.com/ECHOIndiaTrust">
                        <img src={facebook} alt="social icon echo" />
                      </a>
                    </li>
                    <li>
                      <a target='blank' href="https://www.linkedin.com/company/echoindia/">
                        <img src={linkedin} alt="social icon echo" />
                      </a>
                    </li>
                    <li>
                      <a target='blank' href="https://x.com/i/flow/login?redirect_after_login=%2FECHOIndiaTrust">
                        <img src={twitter} alt="social icon echo" />
                      </a>
                    </li>
                    <li>
                      <a target='blank' href="https://www.instagram.com/echoindia1/">
                        <img src={instagram} alt="social icon echo" />
                      </a>
                    </li>
                    <li>
                      <a target='blank' href="https://www.youtube.com/channel/UCrlqLJYrX5BA1MRcB0LbGAg">
                        <img src={youtube} alt="social icon echo" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="coming-soon">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="coming-soon-div">
                <div className="waviy">
                  <span style={{ '--i': 1 }}>c</span>
                  <span style={{ '--i': 2 }}>o</span>
                  <span style={{ '--i': 3 }}>m</span>
                  <span style={{ '--i': 4 }}>i</span>
                  <span style={{ '--i': 5 }}>n</span>
                  <span style={{ '--i': 6 }}>g</span>
                  <span style={{ '--i': 7 }}>s</span>
                  <span style={{ '--i': 8 }}>o</span>
                  <span style={{ '--i': 9 }}>o</span>
                  <span style={{ '--i': 10 }}>n</span>
                </div>

              </div>
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="form-style">
                    <div className="input">
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />

                    </div>
                    <div className="btn">
                      <button type="submit">Notify Me</button>
                    </div>
                  </div>
                </form>
                {message && <p>{message}</p>}
              </div>

            </div>
          </div>
        </div>
      </div> */}
    </DefaultLayout>
  );
};

export default NoMatchPage;
